<template>
    <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    </div>
    <div v-else>

        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="tax.name">{{tax.name}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New subcharge, tax or fee</h3>
           
            <md-button class="md-primary md-raised" @click="fireUpdate">Save</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <CRow :gutters="true">
                <CCol sm="12" lg="5">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Info</div>
                        </md-card-header>

                        <md-card-content>
                            <CRow>
                                <CCol sm="12">
                                    <md-field :class="getValidationClass('name')">
                                        <label>Name</label>
                                        <md-input v-model="tax.name"></md-input>
                                        <span class="md-error" v-if="!$v.tax.name.required">Name is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('type')">
                                        <label for="type">Method</label>
                                        <md-select v-model="tax.type" name="type" id="type" placeholder="Method">
                                            <md-option value="fixed">Fixed amount
                                            </md-option>
                                            <md-option value="percentage">Percentage
                                            </md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.tax.type.required">Type is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('percentageType')">
                                        <label for="type">Apply percentage on</label>
                                        <md-select v-model="tax.percentageType" name="type" id="type" placeholder="Apply percentage on">
                                            <md-option value="base">Apply percentage on base rate
                                            </md-option>
                                            <md-option value="total">Apply percentage on total
                                            </md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.tax.percentageType.required">Type is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('value')">
                                        <span class="md-prefix" v-if="tax.type == 'fixed'">$</span>
                                        <span class="md-prefix" v-if="tax.type == 'percentage'">%</span>
                                        <label>Value</label>
                                        <md-input v-model="tax.value" type="number"></md-input>
                                        <span class="md-error" v-if="!$v.tax.value.required">Value is required</span>
                                    </md-field>

                                    <CRow>
                                        <CCol sm="4">
                                            <md-switch v-model="tax.enabled" class="md-primary">Enabled</md-switch>
                                        </CCol>
                                        <CCol sm="4">
                                            <md-switch v-model="tax.taxable" class="md-primary">Taxable</md-switch>
                                        </CCol>
                                        <CCol sm="4">
                                            <md-switch v-model="tax.geofenced" class="md-primary">Use Geofence</md-switch>
                                        </CCol>
                                    </CRow>
                                    <md-field v-if="tax.geofenced" :class="getValidationClass('geofenceType')">
                                        <label :for="'geofence-type'">Geofence trigger type</label>
                                        <md-select v-model="tax.geofenceType" :name="'geofence-type'" :id="'geofence-type'">
                                            <md-option value="pu-do" >On Pick Up and Drop Off</md-option>
                                            <md-option value="through" >Goes through (useful for tolls)</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.tax.geofenceType.required">Geofence type is required</span>
                                    </md-field>
                                    <md-field v-if="tax.geofenced && tax.geofenceType" :class="getValidationClass('fence')">
                                        <label :for="'tax-fence'">{{ tax.geofenceType == 'pu-do' ? 'PU / DO Tax fence' : 'Goes through fence' }}</label>
                                        <md-select v-model="tax.fence" :name="'tax-fence'" :id="'tax-fence'">
                                            <md-option v-for="geofence in geofences" :value="geofence.id" v-bind:key="geofence.id">{{geofence.code}} | {{geofence.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.tax.fence.required">Value is required</span>
                                        <span class="md-helper-text">{{ tax.geofenceType == 'pu-do' ? 'Tax will be applied if PU or DO locations are inside selected geofence' : 'Tax will be applied when route goes through fence. Useful for tolls' }}</span>
                                    </md-field>
                                    
                                </CCol>
                            </CRow>
                            <CRow class="mt-4">
                                <CCol sm="12">
                                <md-button class="md-raised md-accent full-width-button " @click="destroy">Delete tax</md-button>
                            </CCol>
                            </CRow>
                            
                        </md-card-content>
                    
                    </md-card>
                </CCol>
                <CCol sm="12" lg="7" v-if="tax.geofenced && selectedFence">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Selected geofence</div>
                        </md-card-header>
                        <md-card-content>
                             <GmapMap :center="selectedFence.center" :zoom="selectedFence.zoom" style="width:100%;height:500px" ref="map">
                                <gmap-polygon :paths="selectedFence.path" :editable="true">
                                </gmap-polygon>
                            </GmapMap>
                        </md-card-content>
                    </md-card>
                </CCol>
            </CRow>
        </div>
        <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>


        
        
    </div>
</template>

<script>
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    requiredIf,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
export default {
    name: 'Tax',
    mixins: [validationMixin],
    
    data() {
        return {
            showSnackbar: false,
            position: 'center',
            duration: 7000,
            isInfinity: false,
            snackMessage: '',
            isBusy: true,
            tax: null, 
            geofences: [],
            selectedFence: null,           
           
        }
    },
    
    methods: {
        fireUpdate() {
            if(this.validate()) {
                this.update()
            } else {
                this.$v.tax.$touch()
                
            }
        },
        update() {
            let th = this;
            this.isBusy = true;
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('taxes').doc(this.$route.params.id).update(this.tax)
            .then(function(){
                console.log('Updated');
                th.snackMessage = 'Tax updated';
                th.isBusy = false;
                th.showSnackbar = true;
                //th.$router.push({path: `/app/taxes`})
            }).catch(function(error) {
                th.snackMessage = 'Ooopss something went wrong';
                th.isBusy = false;
                th.showSnackbar = true;
                console.log("Error getting document:", error);
            });
        },
        destroy() {
            let th = this;
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('taxes').doc(this.$route.params.id).delete()
            .then(function(){
                console.log('Deleted');
                th.$router.push({path: `/app/taxes-and-fees`})
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });;
        },
        validate(){
            let name,type,value,fence,percentageType;
            name = this.$v.tax.name.$invalid;
            type = this.$v.tax.type.$invalid;
            fence = this.$v.tax.fence.$invalid;
            value = this.$v.tax.value.$invalid;
            percentageType = this.$v.tax.percentageType.$invalid;
           
            return !name && !type && !value && !fence && !percentageType? true : false ;


        },
        getValidationClass (fieldName) {
            let field = null;

            
            field = this.$v.tax[fieldName];
            
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        async getGeofences() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('geofences').onSnapshot((querySnapshot) => {
                    console.log('Snap shot')
                     this.geofences = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.geofences.push(dataToSave);

                    });
                    resolve()
                });
            })  
        },
        async getTax() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('taxes').doc(this.$route.params.id).get()
                .then(function(doc) {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());
                        th.tax = doc.data();
                        if(th.tax.geofenced) {
                            th.refreshGeofencePreview(th.tax.fence)
                        }
                        
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                    resolve()
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                    reject()
                });
            })  
        },
        refreshGeofencePreview(fenceId) {
            for (let i = 0; i < this.geofences.length; i++) {
                
                if(this.geofences[i].id == fenceId) {
                    this.selectedFence = this.geofences[i];
                }
            }
        },
        async obtainViewResources() {
            await this.getGeofences();
            await this.getTax();
            this.isBusy = false
        }
       
       
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
       
    },
    watch: {
        'tax.fence': {
            handler: function(val,oldVal) {
                this.refreshGeofencePreview(val)
            }
        }
    },
    created () {
        
         this.obtainViewResources()
        
    },
    mounted(){
       
    },
    validations: {
       
        tax: {
            
            name: {
                required
            },
            type: {
                required
            },
            value: {
                required
            },
            percentageType: {
                required: requiredIf(function(tax){
                    return tax.type === 'percentage' ? true : false;
                })
            },
            fence: {
                required: requiredIf(function(tax){
                    return tax.geofenced ? true : false ;

                })
            },
            geofenceType: {
                required: requiredIf(function(tax){
                    return tax.geofenced ? true : false ;

                })
            }
            
        }
    }
}
</script>  